import React, { useState } from 'react';
import { UserAuth } from '../../context/AuthContext';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


const SetDisplayName = ({ onClose }) => {
    const { addDisplayName } = UserAuth();
    const [displayName, setDisplayName] = useState("")

    const handleAddDisplayName = async () => {
        try {
            await addDisplayName(displayName);

        } catch (error) {
            alert("Det skjedde en feil", error)
        }
        addDisplayName(displayName)
        onClose()
    }




    return (
        <div>
            <DialogTitle>Navn</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Her Kan du legge til eller endre Navnet ditt. <br />
                    Navnet vises på tipsene som du er tipsbehandler.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="normal"
                    id="name"
                    label="Navn"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={(e) => { setDisplayName(e.target.value) }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleAddDisplayName}>Lagre</Button>
            </DialogActions>




        </div>
    )
}

export default SetDisplayName