import React, { useState } from 'react'
import Sidebar from '../Sidebar';
import MobileSidebar from '../MobileSidebar';
//MUI
import { useMediaQuery, Card, CardContent, Typography, CardActions, Button, Fab, CardHeader, Avatar, IconButton } from '@mui/material';

const Quiz = () => {
  const isPhone = useMediaQuery('(max-width:990px)');

  //console.log(isPhone)

  return (
    <div className='dashboard-main'>
      {isPhone ? (
        <MobileSidebar /> // Show this component on phones
      ) : (
        <Sidebar className="sidebar-menu" />
      )}
      <div className='dashboard-main-content'>
        <Card>
            <CardHeader
              title="Konkuranse"
              subheader="Rediger Konkuranse"
              
            />
        </Card>
      </div>
    </div>
  )
}

export default Quiz


