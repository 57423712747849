import React, { useState } from 'react'
import { Stack, useMediaQuery, Typography, Box, } from '@mui/material';
import Sidebar from '../Sidebar';
import MobileSidebar from '../MobileSidebar';
import Users from './Users';
import MyUser from './MyUser';
import TipsSettings from './TipsSettings';

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

const Settings = () => {
  const isPhone = useMediaQuery('(max-width:600px)');
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className='settings-main'>
      {isPhone ? (
        <MobileSidebar /> // Show this component on phones
      ) : (
        <Sidebar className="sidebar-menu" />
      )}
      <div className='settings-main-content'>
        <Typography variant="h5" color="primary">Innstillinger</Typography>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Brukere" value="1" />
                <Tab label="Tips" value="2" />
                <Tab label="Mine innstillinger" value="3" />
              </TabList>
            </Box>
            <TabPanel value="1"><Users /></TabPanel>
            <TabPanel value="2"><div><TipsSettings /></div></TabPanel>
            <TabPanel value="3"><div><MyUser /></div></TabPanel>
          </TabContext>
        </Box>
      </div>
    </div>
  )
}

export default Settings