import React, { useState } from 'react'
import { Link as RouterLink, useNavigate, } from 'react-router-dom'
import { UserAuth } from '../../context/AuthContext'
import Logo from "../../img/logo.svg"

import "../../App.css"
//MUI
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material"
import { useMediaQuery } from '@mui/material'

//alert function
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const Login = () => {
    const isPhone = useMediaQuery('(max-width:600px)');
    // input 
    const { signIn, resetPassword, usersList } = UserAuth()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [open, setOpen] = useState(false)
    const [error, setError] = useState('')
    const [resetEmail, setResetEmail] = useState("")
    const [dialogOpen, setDialogOpen] = useState(false)
    const navigate = useNavigate()
    const handleClick = () => {
        setOpen(true);
    };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    const handleSubmit = async (e) => {
        e.preventDefault()
        setError("")
        try {
            await signIn(email, password)
            navigate("/dashboard")
        } catch (e) {
            setOpen(true)
            setError(e.message)
            console.log(e.message)
        }
    }


    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmit(e);
        }
    };
    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleForgotPassword = () => {
        setDialogOpen(true);
    }

    const handleResetPassword = () => {
        const isEmailInList = usersList.some(user => user.email === resetEmail);
        if (isEmailInList) {
            resetPassword(resetEmail)
            alert("Epost om tilbakestilling er sendt.")
            setTimeout(() => {
                setDialogOpen(false)
            }, 200);
        } else {
            alert("Denne eposten finnes ikkje i våre systemer eller vi har ikke fått hentet data, vent litt og prøv igjen.")
        }

    }

    return (
        <div className='login-main'>
            <div className='login-left'>
                <div className='login-logo-container'>
                    <img src={Logo} alt="Tipsight logo" className='login-logo' />
                    <span className='login-logo-text'>version 2.6</span>
                    <span className='login-logo-text'></span>
                </div>
            </div>
            <div className='login-right'>
                <Box component="form">
                    <Stack spacing={2}>
                        <TextField
                            id="email"
                            label="Email"
                            placeholder='ola@tipsight.no'
                            onChange={(e) => { setEmail(e.target.value) }}
                        />
                        <TextField
                            id="password"
                            type="password"
                            label="Password"
                            onChange={(e) => { setPassword(e.target.value) }}
                            onKeyDown={handleKeyPress}
                        />
                        <Button variant={isPhone ? 'contained' : 'outlined'} color="primary" onClick={handleSubmit} type="submit">
                            Sign in
                        </Button>

                        <Button variant={"text"} color="primary" onClick={handleForgotPassword} >
                            Glemt passord?
                        </Button>

                    </Stack>
                </Box>

            </div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {error}
                </Alert>
            </Snackbar>

            <Dialog open={dialogOpen} onClose={handleCloseDialog}>
                <DialogTitle>Glemt passord?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Har du glemt passordet ditt?, skriv in mailen din her. du vil få en epost med instruksjoner for tilbakestilling av passordet ditt.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Epost addresse"
                        type="email"
                        fullWidth
                        variant="standard"
                        onChange={(e) => { setResetEmail(e.target.value) }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Avbryt</Button>
                    <Button onClick={handleResetPassword}>Tilbakestill</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
export default Login