import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip'
import Stack from "@mui/material/Stack"
import Divider from '@mui/material/Divider';
import axios from 'axios';
import { onSnapshot, collection, } from 'firebase/firestore';
import { db } from '../../firebase';


const NordfjordeidPort = () => {
    return (
        <iframe
            height="600vh"
            width="1065vw"
            src="https://www.youtube.com/embed/bza-113WPlo?autoplay=1"
            title="YouTube video player"
          
            allowFullScreen
        ></iframe>
    );
};

const MaloyBy = () => {
    return (
        <iframe
            height="600vh"
            width="1065vw"
            src="https://www.youtube.com/embed/-VxViCUlZVk?autoplay=1"
            title="YouTube video player"
           
            allowFullScreen
        ></iframe>
    );
};

const Krokenesfyr = () => {
    return (
        <iframe
            height="100%"
            width="100%"
            src="https://www.youtube.com/embed/okxd7RbQJ8s?autoplay=1"
            title="YouTube video player"

            allowFullScreen
        ></iframe>
    );
};

const VestKapp = () => {
    return (
        <iframe
            height="600vh"
            width="1065vw"
            src="https://www.youtube.com/embed/i5qOpeOue7E?autoplay=1"
            title="YouTube video player"
            
            allowFullScreen>
        </iframe>
    );
};

const videos = [
    { id: 'atjCv4LYjTI', component: NordfjordeidPort },
    { id: '-VxViCUlZVk', component: MaloyBy },
    { id: 'okxd7RbQJ8s', component: Krokenesfyr },
    { id: 'i5qOpeOue7E', component: VestKapp },
];

const NewsRull = () => {
    const [currentItemIndex, setCurrentItemIndex] = useState(0); // Start from 0 for the first card
    const [data, setData] = useState([{}]);
    const isVideo = currentItemIndex % 2 !== 0;
    const currentIndex = Math.floor(currentItemIndex / 2);
    const [weatherData, setWeatherData] = useState(null);
    const apiKey = "ffeeb296d3c1b81f4c95e3852a22524f";
    const lat = 61.93535;
    const lon = 5.11362;

    console.log("index", currentItemIndex)

   
    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, 'tipstream'), (querySnapshot) => {
            const newData = [];
            querySnapshot.forEach((doc) => {
                newData.push({
                    id: doc.id,
                    ...doc.data(),
                });
            });
    
            setData(newData);
        });
    
        return () => {
          
            unsubscribe();
        };
    }, []);
    


    useEffect(() => {
          const interval = setInterval(() => {
             setCurrentItemIndex((prevIndex) => (prevIndex + 1) % (data.length * 2));
        }, 5000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `https://api.met.no/weatherapi/locationforecast/2.0/complete?lat=${lat}&lon=${lon}`
                );
                setWeatherData(response.data);
            } catch (error) {
                console.error('Error fetching weather data:', error);
            }
        };

        fetchData();
    }, []);
    
    return (
        <div>

            {isVideo && videos[currentIndex] ? (
                <div className='tip-stream-yt'> {videos[currentIndex].component()}  </div>

            ) : (
                <div className='news-roll-outer-container'>
                    <div className='news-roll-container'>
                        <div className='news-roll-text-container'>
                            <Chip label={data[currentIndex].category} color="primary" sx={{ marginbottom: 20 }} />
                            <Stack spacing={1}>
                                <Typography variant="h4" component="div">
                                    {data[currentIndex].title}
                                </Typography>
                                <Typography variant="body2">
                                    {data[currentIndex].content}
                                </Typography>
                            </Stack>
                        </div>
                    </div>
                    <div className='news-roll-weather-container'>
                        {weatherData ?
                            <div className='news-roll-weather-inner-container'>'
                                <Stack spacing={1}>
                                    <Typography variant="h5" component="div">
                                        Måløy nå
                                    </Typography>

                                    <Typography variant="body1" component="div">
                                        Temperatur : {weatherData.properties.timeseries[0].data.instant.details.air_temperature}°
                                    </Typography>
                                    <Typography variant="body1" component="div">
                                        Vind : {weatherData.properties.timeseries[0].data.instant.details.wind_speed} m/s
                                    </Typography>
                                    <Divider />
                                    <Typography variant="h6" component="div">
                                        Neste 6 timene
                                    </Typography>

                                    <Typography variant="body1" component="div">
                                        Max temp : {weatherData.properties.timeseries[0].data.next_6_hours.details.air_temperature_max}°
                                    </Typography>
                                    <Typography variant="body1" component="div">
                                        Min temp : {weatherData.properties.timeseries[0].data.next_6_hours.details.air_temperature_min}°
                                    </Typography>
                                </Stack>
                            </div>
                            :
                            <div>
                                <Typography variant="body1" component="div">
                                    Feil, kunne ikke hente værdata
                                </Typography>

                            </div>
                        }

                    </div>
                </div>
            )}
        </div>
    );
};

export default NewsRull;
