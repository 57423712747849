import React, { useState, useEffect } from 'react';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { collection, getDocs, doc, updateDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase';
import { EmailSender } from '../../context/EmailContext';
import { UserAuth } from "../../context/AuthContext"

const EditableChip = ({ id }) => {
  const { user } = UserAuth()
  const { sendTipAssigned } = EmailSender()
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [options, setOptions] = useState([]);
  const [data, setData] = useState({});

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'users'));
        const fetchedOptions = querySnapshot.docs.map((doc) => doc.data().name);
        setOptions(fetchedOptions);
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    const documentRef = doc(db, 'tips', id);
    const unsubscribe = onSnapshot(documentRef, (snapshot) => {
      if (snapshot.exists()) {
        setData(snapshot.data());
      } else {
        setData(null);
      }
    });

    fetchOptions();

    return () => {
      unsubscribe();
    };
  }, [id]);

  const handleClick = () => {
    setIsEditMode(true);
  };

  const handleBlur = async (e, newValue) => {
    setIsEditMode(false);
    if (selectedOption && selectedOption !== data.tipsHandler) {
      try {
        const docRef = doc(db, 'tips', id);
        await updateDoc(docRef, { tipsHandler: selectedOption });
        sendTipAssigned(selectedOption, id, user.email)
        console.log('Document updated successfully!');
      } catch (error) {
        console.error('Error updating document:', error);
      }
    }
  };




  const handleChange = (e, newValue) => {
    setSelectedOption(newValue);
  };

  if (isEditMode) {
    return (
      <Autocomplete
        value={selectedOption}
        onChange={handleChange}
        onBlur={handleBlur}
        options={options}
        renderInput={(params) => (
          <TextField {...params} autoFocus margin="dense" />
        )}
      />
    );
  }

  return <Chip label={data.tipsHandler} color='primary' onClick={handleClick} />;
};

export default EditableChip;
