import React, { useState } from 'react'
import { Card, CardActions, CardContent, Button, Typography, CardHeader, TextField, Snackbar } from "@mui/material"
import { UserAuth } from '../context/AuthContext'
import MuiAlert from '@mui/material/Alert';


const ResertPassword = () => {
    const { createUser } = UserAuth()
    const [email, setEmail] = useState("");
    const [displayName, setDisplayName] = useState("")
    const [password, setPassword] = useState('')

    const [error, setError] = useState('')
    const [openMessage, setOpenMessage] = useState(false)
    const [openError, setOpenError] = useState(false)
    const [goodMessage, setGoodMessage] = useState("")
    const [showPassword, setShowPassword] = useState(false)


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenError(false);
        setOpenMessage(false);
    };

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });



    const handleSubmit = async (e) => {
        e.preventDefault()
        setError("")
        try {
            await createUser(email, password, displayName);
            setOpenMessage(true)
            setGoodMessage("Bruker oppretta")
            setEmail("")
            setPassword("")
        } catch (e) {
            setError(e.message)
            setOpenError(true)
            console.log(e.message)
        }
        setShowPassword(true)
    }

    return (
        <div className='resetpassword-container'>
            <Card sx={{ minWidth: 335 }}>
                <CardHeader>Glemt passord?</CardHeader>
                <CardContent>
                    <Typography>
                        Hei, her kan du lage deg en bruker, skriv inn epost og passord
                    </Typography>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Navn"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(e) => { setDisplayName(e.target.value) }}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Epost addresse"
                        type="email"
                        fullWidth
                        variant="standard"
                        onChange={(e) => { setEmail(e.target.value) }}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Passord"
                        type="password"
                        fullWidth
                        variant="standard"
                        onChange={(e) => { setPassword(e.target.value) }}
                    />
                </CardContent>
                <CardActions>
                    <Button onClick={handleSubmit}>Lag bruker</Button>
                </CardActions>
            </Card>

            <Snackbar open={openMessage} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    {goodMessage}
                </Alert>
            </Snackbar>

            <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {error}
                </Alert>
            </Snackbar>

        </div>
    )
}

export default ResertPassword