import React, { useState, useEffect } from 'react';
import { UserAuth } from '../../context/AuthContext';
import { Paper, Typography, TextField, Button, Divider, Box, Stack, useMediaQuery, Snackbar, } from '@mui/material'
import { onSnapshot, collection } from 'firebase/firestore';
import { db } from '../../firebase';
import RemoveIcon from '@mui/icons-material/Remove';
import MuiAlert from '@mui/material/Alert';

const Users = () => {
    const isPhone = useMediaQuery('(max-width:600px)');
    const { createUser } = UserAuth();
    //  const { user } = UserAuth()
    const { handleDeleteUser } = UserAuth()

    const [users, SetUsers] = useState([])
    const [email, setEmail] = useState("");
    const [displayName, setDisplayName] = useState("")
    const [password, setPassword] = useState('Vinter200')
    const [error, setError] = useState('')
    const [openMessage, setOpenMessage] = useState(false)
    const [openError, setOpenError] = useState(false)
    const [goodMessage, setGoodMessage] = useState("")
    const [showPassword, setShowPassword] = useState(false)

    //const { resetPassword } = UserAuth(); denne brukes ikkje. er bare her atm


    useEffect(() => {
        const usersRef = collection(db, "users");
        const unsubscribe = onSnapshot(usersRef, (snapshot) => {
            const data = snapshot.docs.map((doc) => {
                return { id: doc.id, ...doc.data() };
            });
            SetUsers(data);
        });
        return () => {
            unsubscribe();
        };

    }, []);



    const handleSubmit = async (e) => {
        e.preventDefault()
        setError("")
        try {
            await createUser(email, password, displayName);
            setOpenMessage(true)
            setGoodMessage("Bruker oppretta")
            setEmail("")
            setPassword("")
        } catch (e) {
            setError(e.message)
            setOpenError(true)
            console.log(e.message)
        }
        setShowPassword(true)
    }

    const removeUser = async (uid) => {
        setError("")
        try {
            await handleDeleteUser(uid);
            setOpenMessage(true)
            setGoodMessage("Bruker sletta")
        } catch (e) {
            setError(e.message)
            setOpenError(true)
            console.log(e.message)
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenError(false);
        setOpenMessage(false);
    };

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });


    return (
        <div className='users-content'>
            <Stack spacing={2} direction={isPhone ? "column" : "row"}>
                <Paper elevation={3} sx={{ minWidth: "max-content" }}>
                    <Box sx={{ padding: 2 }}>
                        <Stack spacing={1}>
                            <Typography variant="h6">Brukere:</Typography>
                            <Divider />
                            {users.map((row, index) => (
                                <Box key={index} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 2 }}>
                                    <Typography variant="body1">{row.name}</Typography>
                                </Box>
                            ))}
                        </Stack>
                    </Box>
                </Paper>

                <Paper elevation={3}>
                    <Box sx={{ padding: 2 }}>
                        <Typography variant="h6">Legg til bruker:</Typography>
                        <Divider />
                        <Stack spacing={2} sx={{ marginTop: 2, textAlign: "center" }}>
                            <TextField size='small' label="Navn" onChange={(e) => { setDisplayName(e.target.value) }} />
                            <TextField size='small' label="Epost" onChange={(e) => { setEmail(e.target.value) }} />
                            {showPassword ? <Typography variant="body1">Se mail for setting av passord</Typography> : <Box sx={{ padding: 1 }}></Box>}
                            <Button onClick={handleSubmit}>Lag bruker</Button>
                        </Stack>
                    </Box>
                </Paper>
            </Stack>

            <Snackbar open={openMessage} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    {goodMessage}
                </Alert>
            </Snackbar>

            <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {error}
                </Alert>
            </Snackbar>
        </div>

    )
}

export default Users

