import React from 'react'
import Sidebar from '../Dashboard/Sidebar'
import MyTipsTabel from '../components/datagrids/MyTipsTabel'
import MobileSidebar from '../Dashboard/MobileSidebar';
import { useMediaQuery } from '@mui/material';
import "../App.css"

const MyTips = () => {
    const isPhone = useMediaQuery('(max-width:600px)');
    return (
        <div className='Tips-main'>
            {isPhone ? (
                <MobileSidebar /> 
            ) : (
                <Sidebar className="sidebar-menu" />
            )}
            <div className='Tips-main-content'>
                <MyTipsTabel />
            </div>
        </div>
    )
}

export default MyTips