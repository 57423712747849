import './App.css';
import React, { useState } from 'react';
import { AuthContextProvider } from './context/AuthContext';
import { EmailContextProvider } from "./context/EmailContext"
import { Route, Routes } from "react-router-dom";
import Login from './Dashboard/userHandling/Login';
import Dashboard from './Dashboard/Dashboard';
import Tips from "./Tips/Tips"
import MyTips from './Tips/MyTips';
import OneTips from './components/OneTips';
import TipStream from './Dashboard/TipStream/TipStream';
import CloudShift from './Dashboard/CloudShift/CloudShift';
import Settings from './Dashboard/settings/Settings';
import ProtectedRoute from './components/ProtectedRoute';
import TipStreamAdmin from './Dashboard/TipStream/TipStreamAdmin';
import ResertPassword from './components/ResetPassword';
import Quiz from './Dashboard/Quiz/Quiz';
import Crossword from "./Dashboard/crossword/Crossword"
import Onecrossword from "./Dashboard/crossword/Onecrossword"


//mui theme
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Button, Fab } from '@mui/material';
import Comp from './Dashboard/comp/Comp';



const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#474aee',
    },
    secondary: {
      main: '#f50057',
    },
  },
  typography: {
    fontFamily: 'Inter Medium, sans-serif',
  },
});

function App() {
  // const [supportChat, setSupportChat] = useState(false);
  // const getHelp = () => {
  //   setSupportChat(!supportChat);
  // };

  return (
    <AuthContextProvider>
      <EmailContextProvider>
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path='/' element={<Login />} />
            <Route path='/dashboard' element={
              <ProtectedRoute >
                <Dashboard />
              </ProtectedRoute>
            } />
            <Route path='/tips' element={
              <ProtectedRoute >
                <Tips />
              </ProtectedRoute>
            } />
            <Route path='/mytips' element={
              <ProtectedRoute >
                <MyTips />
              </ProtectedRoute>
            } />
            <Route path='/cloudshift' element={
              <ProtectedRoute >
                <CloudShift />
              </ProtectedRoute>
            } />
            <Route path='/tipstream' element={
              <TipStream />
            } />
            <Route path='/admin-tipstream' element={
              <ProtectedRoute >
                <TipStreamAdmin />
              </ProtectedRoute>
            } />
            <Route path='/tip/:id' element={
              <ProtectedRoute >
                <OneTips />
              </ProtectedRoute>
            } />
            <Route path='/settings' element={
              <ProtectedRoute >
                <Settings />
              </ProtectedRoute>
            } />

            <Route path='/quiz' element={
              <ProtectedRoute >
                <Quiz />
              </ProtectedRoute>
            } />

            <Route path='/crosswords' element={
              <ProtectedRoute >
                <Crossword />
              </ProtectedRoute>
            } />

            <Route path='/konkuranse' element={
              <ProtectedRoute >
                <Comp />
              </ProtectedRoute>
            } />

            <Route path='/crossword/:id' element={
              <ProtectedRoute >
                <Onecrossword />
              </ProtectedRoute>
            } />


            <Route path='/sdksete-sdsdakwl' element={
              <ResertPassword />
            } />

          </Routes>
          {
            /** supportChat ? (
            <div>
              <Button variant='outlined' sx={{ position: "fixed", bottom: 361, right: 20, zIndex: 1, color: "#fff", borderColor: "white" }} onClick={getHelp}>Close</Button>
              <iframe
                title="Example iframe"
                width="350"
                height="400"
                src="https://telegraph-olive.vercel.app/sendchat"
                //src="http://localhost:3001/sendchat"
                style={{
                  position: 'fixed',
                  bottom: '10px',
                  right: '10px',
                  border: 'none'
                }}
              >
                Your browser does not support iframes.
              </iframe>
            </div>
          ) : (
            <Fab variant="extended" size="medium" color="primary" sx={{ position: "fixed", bottom: 20, right: 20, }} onClick={getHelp}>
              Hjelp
            </Fab>
          ) */
          }
        </ThemeProvider>
      </EmailContextProvider>
    </AuthContextProvider>
  );
}

export default App;
