import React, { useState, useEffect } from 'react'
import { collection, getDocs, query, where, Timestamp, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import { useMediaQuery } from '@mui/material';

import { ThemeProvider, createTheme } from '@mui/material/styles';



//theme
const themeColor = "#474aee"

const TipsUsedInCase = () => {
  const isPhone = useMediaQuery('(max-width:600px)');
  const [documentsWithUsedInCase, setDocumentsWithUsedInCase] = useState(0);

  useEffect(() => {
    const collectionRef = collection(db, 'tips');
    const fetchDocumentsWithUsedInCase = () => {
      const q = query(collectionRef, where('usedInCase', '==', true));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        setDocumentsWithUsedInCase(snapshot.size);
      });
      return unsubscribe;
    };
    const unsubscribe = fetchDocumentsWithUsedInCase();

    // Cleanup function to unsubscribe from the snapshot listener
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <Card sx={isPhone ? { maxWidth: 300, minWidth: 300, } : { maxWidth: 345, minWidth: 300, }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: themeColor }} aria-label="recipe">
            <AnnouncementIcon />
          </Avatar>
        }
      />
      {
        isPhone ?
          <CardContent>
            <Typography variant="h5">
              Tips bruk i sak : {documentsWithUsedInCase}
            </Typography>
          </CardContent>
          :
          <CardContent>
            <Typography variant="h5">
              Tips bruk i sak
            </Typography>
            <Typography variant="h5">
              {documentsWithUsedInCase}
            </Typography>
          </CardContent>
      }

    </Card>
  )
}

export default TipsUsedInCase