import React, { useState, useEffect } from 'react'
import { collection, getDocs, query, where, Timestamp, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import { useMediaQuery } from '@mui/material';

//theme
const themeColor = "#474aee"

const TipsToday = () => {
  const [documentsAddedToday, setDocumentsAddedToday] = useState(0);
  const isPhone = useMediaQuery('(max-width:600px)');
  useEffect(() => {
    const collectionRef = collection(db, 'tips');
    const fetchDocumentsAddedToday = () => {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const startOfToday = Timestamp.fromDate(today);
      const q = query(collectionRef, where('date', '>=', startOfToday));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        setDocumentsAddedToday(snapshot.size);
      });

      return unsubscribe;
    };
    const unsubscribe = fetchDocumentsAddedToday();
    // Cleanup function to unsubscribe from the snapshot listener
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <Card sx={isPhone ? { maxWidth: 300, minWidth: 300, } : { maxWidth: 345, minWidth: 300, }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: themeColor }} aria-label="recipe">
            <AnnouncementIcon />
          </Avatar>
        }
      />
      {
        isPhone ?
          <CardContent>
            <Typography variant="h5">
            Innsendt i dag : {documentsAddedToday}
            </Typography>
          </CardContent>
          :
          <CardContent>
            <Typography variant="h5">
            Innsendt i dag
            </Typography>
            <Typography variant="h5">
              {documentsAddedToday}
            </Typography>
          </CardContent>
      }

    </Card>
  )
}

export default TipsToday