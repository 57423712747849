import { createContext, useContext, useEffect, useState } from "react";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged, sendPasswordResetEmail, deleteUser, updateProfile, updatePassword } from "firebase/auth";
import { setDoc, doc, deleteDoc, serverTimestamp, onSnapshot, collection } from "firebase/firestore";
import { auth } from "../firebase.js"
import { db } from "../firebase.js";

const UserContext = createContext()
export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({})
  const [usersList, setUsersList] = useState([])

  const createUser = async (email, password, displayName) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password,);
      if (userCredential) {
        const uid = userCredential.user.uid;
        await addUserToDatabase(uid, email, displayName);
        await resetPassword(email);
        //console.log("test",userCredential.user)
        updateProfile(userCredential.user, {
          displayName: displayName,
        }).then(() => {
          console.log("profile updated success")
        }).catch((error) => {
          console.log("there was an error updating profile", error)
        });

        return userCredential.user;
      }
    } catch (error) {
      console.error('Feil under oppretting av bruker:', error);
      throw error;
    }
  };

  const handleDeleteUser = async (uid) => {
    try {
      deleteUser(user.uid);
      const catDocRef = doc(db, "users", uid);
      await deleteDoc(catDocRef);
    } catch (error) {
      console.error('Feil under oppretting av bruker:', error);
      throw error;
    }
    return deleteUser(auth, uid)
  }

  const addDisplayName = async (displayName) => {
    updateProfile(user, {
      displayName: displayName,
    }).then(() => {
      console.log("profile updated success")
    }).catch((error) => {
      console.log("there was an error updating profile", error)
    });
  }

  const signIn = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password)
  }

  const logout = () => {
    return signOut(auth)
  }

  const addUserToDatabase = async (uid, email, displayName) => {
    const userDocRef = doc(db, 'users', uid);
    await setDoc(userDocRef, {
      uid: uid,
      email: email,
      name: displayName,
      wantNotifications: true,
      created: serverTimestamp()
    });

  }

  const resetPassword = async (email) => {
    return sendPasswordResetEmail(auth, email);
  };


  const setNewPassword = async (newPassword) => {
    updatePassword(user, newPassword).then(() => {
      console.log("reset passord")
    }).catch((error) => {
      // An error ocurred
      // ...
    });
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      console.log(currentUser)
      setUser(currentUser)
    })
    return () => {
      unsubscribe();
    }
  }, [])

  useEffect(() => {
    const usersRef = collection(db, 'users');
    const unsub = onSnapshot(usersRef, (snapshot) => {
      const usersData = [];
      snapshot.forEach((doc) => {
        usersData.push({ id: doc.id, ...doc.data() });
      });
      setUsersList(usersData);
    });

    return () => {
      unsub();
    };
  }, []);


  return (
    <UserContext.Provider value={{ createUser, user, logout, signIn, resetPassword, handleDeleteUser, addDisplayName, usersList, setNewPassword }}>
      {children}
    </UserContext.Provider>
  );
}

export const UserAuth = () => {
  return useContext(UserContext);
};

