import React from 'react'
import Sidebar from '../Dashboard/Sidebar'
import Alltips from '../components/datagrids/Alltips'
import MobileSidebar from '../Dashboard/MobileSidebar';
import { useMediaQuery } from '@mui/material';
import "../App.css"
const Tips = () => {
  const isPhone = useMediaQuery('(max-width:990px)');
  return (
    <div className='Tips-main'>
       {isPhone ? (
                <MobileSidebar /> // Show this component on phones
            ) : (
                <Sidebar className="sidebar-menu" />
            )}
    <div className='Tips-main-content'>
      <Alltips/> 
    </div>
  </div>
  )
}

export default Tips