import React, { useState, useEffect } from 'react'
import "../../App.css"
import Logo from "../../img/logo.svg"
import Typography from '@mui/material/Typography';
import NewsRull from './NewsRull';





const TipStream = () => {


    const headline = "Tre av fire kan rekne med å kome inn på førstevalet sitt Første inntak til vidaregåande skule 2023 / 2024 i Vestland er klart, og 22.700 søkarar i fylket får no svar på søknaden sin om skuleplass.Om lag 74 prosent av søkarane får førstevalet sitt."

    return (
        <div className='tip-stream-main'>
            <div className='tip-stream-content'>
                    <NewsRull />
            </div>
            <div className='tip-stream-footer'>
                <div className='tip-stream-footer-content'>
                    <img src={Logo} alt="logo" className='tip-stream-logo' />
                    <Typography variant="body1" gutterBottom sx={{ width: 800 }}>
                        {headline}
                    </Typography>
                </div>
            </div>
        </div>

    )
}

export default TipStream