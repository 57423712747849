import React, { useState, useEffect } from 'react'
import 'firebase/storage';
import { addDoc, collection, serverTimestamp, Timestamp, onSnapshot, query, orderBy, limit } from 'firebase/firestore';
import { storage } from "../../firebase"
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db } from '../../firebase';
import Sidebar from '../Sidebar';
import MobileSidebar from '../MobileSidebar';
import moment from 'moment';

import { UserAuth } from "../../context/AuthContext"
//import Logo from "../../img/FjT-LOGO-NEGATIV.svg"

//MUI
import { Stack, useMediaQuery, Typography, Card, CardContent, Button, Snackbar, Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText, TextField } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { DataGrid } from '@mui/x-data-grid';

//MUI icons
import ImageIcon from '@mui/icons-material/Image';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const columns = [
    {
        field: 'filename',
        headerName: 'Navn',
        width: 150,
        editable: false,
    },
    {
        field: 'date',
        headerName: 'Endret',
        width: 200,
        editable: false,
    },
    {
        field: 'uid',
        headerName: 'Uid',
        width: 280,
        editable: false,
    },
    {
        field: 'Button',
        headerName: 'Last ned',
        sortable: false,
        editable: false,
        width: 100,
        renderCell: RenderButtonCell,
    },
];
function RenderButtonCell(params) {
    const { imageUrl, filename } = params.row;

    const handleDownload = async () => {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        link.click();
    };

    return (
        <Button onClick={handleDownload} color="primary">last ned</Button>
    );
}


const CloudShift = () => {
    const { user } = UserAuth()
    const isPhone = useMediaQuery('(max-width:990px)');
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([])
    const [image, setImage] = useState(null);
    const [filename, setfilename] = useState('');
    const [openWaitMessage, setOpenWaitMessage] = useState(false)
    const [openMessage, setOpenMessage] = useState(false)
    const [openBadMessage, setOpenBadMessage] = useState(false)
    const [badMessage, setBadMessage] = useState("Error sending tips")
    const goodMessage = "bilde lastet opp"
    console.log(data)

    useEffect(() => {
        const fileTransferRef = collection(db, "filetransfer");
        const q = query(fileTransferRef, orderBy("date", "desc"));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const data = snapshot.docs.map((doc) => {
                let date = doc.data().date ? moment(doc.data().date.toDate()).format('DD/MM/YYYY HH:mm:ss') : moment().format('DD/MM/YYYY HH:mm:ss');

                return { id: doc.id, ...doc.data(), date };
            });
            setData(data);
        });
        return () => {
            unsubscribe();
        };
    }, []);



    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenBadMessage(false);
        setOpenMessage(false)
    };

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleUploadIntent = () => {
        UploadImage()
    }
    const UploadImage = async () => {
        setOpenWaitMessage(true)
        const collectionRef = collection(db, "filetransfer");
        if (filename !== "" && filename.length > 0) {
            try {
                let imageUrl = "";
                if (image != null) {
                    const imageRef = ref(storage, `filetransfer/img/${filename}`);
                    await uploadBytes(imageRef, image);
                    imageUrl = await getDownloadURL(imageRef);
                }
                await addDoc(collectionRef, {
                    imageUrl: imageUrl,
                    filename: filename,
                    uid: user.uid,
                    date: serverTimestamp(),
                });
                setOpenWaitMessage(false)
                setOpenMessage(true);
                setOpen(false)
            } catch (error) {
                console.error('An error occurred:', error);
                setOpenWaitMessage(false)
                setOpenBadMessage(true);
                setBadMessage(`Error sending data: ${error}`);
            }
            console.log("funka")
        } else {

            console.log("Ugyldig filnavn.");
        }
    }







    return (
        <div className='cloudshift-dashboard-main'>
            {isPhone ? (
                <MobileSidebar />
            ) : (
                <Sidebar className="sidebar-menu" />
            )}
            <div className='cloudshift-dashboard-main-content'>
                <Stack spacing={2}>
                    <Stack spacing={2} direction={isPhone ? "column" : "row"}>
                        <Typography variant="h4" color="primary">Bilder</Typography>
                        <Button variant='outlined' onClick={setOpen} sx={isPhone ? { width: "max-content" } : { width: 200 }}>Last opp</Button>
                    </Stack>
                    <Card sx={isPhone ? { width: 310 } : {}}>
                        <CardContent>
                            <DataGrid
                                rows={data}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 10,
                                        },
                                    },
                                }}
                                pageSizeOptions={[10]}
                                disableRowSelectionOnClick
                            />

                        </CardContent>

                    </Card>
                </Stack>
            </div>


            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Last opp bilde</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Her kan du laste opp bilde fra telefon/datamaskin så laste ned på telefon/datamaskin. bildet blir automatisk sletta etter 7 dager
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Navn på fil"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(e) => { setfilename(e.target.value) }}
                    />
                    <div >
                        <input
                            className='fileInput'
                            accept="*"
                            id="contained-button-file"
                            multiple
                            type="file"
                            onChange={(e) => { setImage(e.target.files[0]) }}
                        />
                        <label htmlFor="contained-button-file">
                            <Button
                                variant="outlined"
                                sx={{ marginTop: 2 }}
                                color="primary"
                                startIcon={<CloudUploadIcon />}
                                component="span"
                            >
                                Velg bilde
                            </Button>
                        </label>
                        {image && <Typography variant="body1" color="initial">Bilde er lastet opp: <br />{image.name}</Typography>}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpen(false) }}>Cancel</Button>
                    <Button onClick={handleUploadIntent}>Last opp</Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={openMessage} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    {goodMessage}
                </Alert>
            </Snackbar>

            <Snackbar open={openBadMessage} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {badMessage}
                </Alert>
            </Snackbar>

            <Snackbar open={openWaitMessage} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
                    {"laster opp bilde. vennligst vent"}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default CloudShift