import React, { useEffect, useState } from 'react';
import {
    AreaChart,
    Area,
    XAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from 'recharts';
import { Card, useMediaQuery, Typography, CardContent } from '@mui/material';
import { collection, onSnapshot, query, orderBy } from 'firebase/firestore';
import { db } from '../../firebase';
import moment from 'moment';

const TipsSent = () => {
    const [data, setData] = useState([]);


    useEffect(() => {
        const collectionRef = collection(db, 'tips');
        const q = query(collectionRef, orderBy('date', 'desc'));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const groupedData = {};
            snapshot.docs.forEach((doc) => {
                const date = moment(doc.data().date.toDate());
                const week = date.week(); // Get the week number

                // Count the number of documents for each week
                if (groupedData[week]) {
                    groupedData[week].total += 1;
                } else {
                    groupedData[week] = { week, total: 1 };
                }
            });

            // Calculate the last 10 weeks
            const maxWeek = moment().week(); // Current week number
            const minWeek = maxWeek - 9; // The last 10 weeks
            const chartData = Array.from({ length: 10 }, (_, i) => {
                const week = maxWeek - i;
                return { week, total: groupedData[week]?.total || 0 };
            }).reverse();

            setData(chartData);
        });

        return () => {
            unsubscribe();
        };
    }, []);


  

    const isPhone = useMediaQuery('(max-width:600px)');

    return (
        <Card
            sx={
                isPhone
                    ? { height: "max-content", width: 320, display: 'flex', flexDirection: "column" }
                    : { height: "max-content", width: 470, display: 'flex', flexDirection: "column" }
            }
        >
            <CardContent>
                <Typography variant="h5" color="initial">Siste 10 veker</Typography>
                <ResponsiveContainer width="100%" height="100%" aspect={2 / 1}>
                    <AreaChart
                        width={730}
                        height={250}
                        data={data}
                        margin={{ top: 10, right: 30, left: 10, bottom: 0 }}
                    >
                        <defs>
                            <linearGradient id="total" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                                <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                            </linearGradient>
                        </defs>
                        <XAxis dataKey="week" stroke="gray" />
                        <CartesianGrid strokeDasharray="3 3" className="chartGrid" />
                        <Tooltip />
                        <Area
                            type="monotone"
                            dataKey="total"
                            stroke="#8884d8"
                            fillOpacity={1}
                            fill="url(#total)"
                        />
                    </AreaChart>
                </ResponsiveContainer>
            </CardContent>
        </Card>
    );
};

export default TipsSent;
