import React, { useState, useEffect } from 'react'
import { useMediaQuery, Paper, Typography, TextField, Card, CardContent, MenuItem, Button, Stack } from "@mui/material"
import MobileSidebar from '../MobileSidebar';
import Sidebar from '../Sidebar';
import { onSnapshot, collection, addDoc, serverTimestamp, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../../firebase';
const TipStreamAdmin = () => {
  const [data, setData] = useState([{}])
  const [category, setCategory] = useState([])
  const isPhone = useMediaQuery('(max-width:990px)');
  const [newCategory, setNewCategory] = useState("")
  const [newTitle, setNewTitle] = useState("")
  const [content, setNewContent] = useState("")

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'tipstream'), (querySnapshot) => {
      const data = [];
      querySnapshot.forEach((doc) => {
        data.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      setData(data);
    });

    const unsubCat = onSnapshot(collection(db, 'tipscategory'), (querySnapshot) => {
      const catData = [];
      querySnapshot.forEach((doc) => {
        catData.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      setCategory(catData);
    });

    return () => {
      unsubscribe();
      unsubCat()
    };
  }, []);


  const addNewCard = async () => {
    const addData = {
      title: "",
      content: "",
      updated: serverTimestamp(),
      category: "null",
    }
    try {
      const collectionRef = collection(db, "tipstream");
      const newDocumentRef = await addDoc(collectionRef, addData);
      console.log('Document added with ID: ', newDocumentRef.id);
    } catch (error) {
      console.error('Error adding document: ', error);
    }

  }

  const updateCard = async (id) => {
    const addData = {
      title: newTitle,
      content: content,
      updated: serverTimestamp(),
      category: newCategory,
    }
    try {
      const documentRef = doc(db, "tipstream", id);
      await updateDoc(documentRef, addData);
      console.log('Document successfully updated!');
    } catch (error) {
      console.error('Error updating document: ', error);
    }

  }
  const deleteCard = async (id) => {
    try {
      const documentRef = doc(db, "tipstream", id);
      await deleteDoc(documentRef);
      console.log('Document successfully deleted!');
    } catch (error) {
      console.error('Error deleting document: ', error);
    }

  }

  return (
    <div className='admin-tip-stream-main'>
      {isPhone ? (
        <MobileSidebar /> 
      ) : (
        <Sidebar className="sidebar-menu" />
      )}

      <div className='admin-tip-stream-main-content'>
        <div className='admin-tip-stream-inner-content'>
          {data.map((card) => (
            <Card elevation={0} sx={{ width: 400, height: 500 }} key={card.id}>
              <CardContent>
                <TextField helperText="Tittel" value={card.title} variant="outlined" fullWidth margin="normal" onChange={(e) => { setNewTitle(e.target.value) }} />
                <TextField helperText="Brødtekst" value={card.content} variant="outlined" fullWidth multiline rows={4} margin="normal" onChange={(e) => { setNewContent(e.target.value) }} />
                <TextField
                  margin="normal"
                  fullWidth
                  select
                  helperText="Velg kategori"
                  defaultValue={card.category}
                  onChange={(e) => { setNewCategory(e.target.value) }}
                >
                  {category.map((option) => (
                    <MenuItem key={option.index} value={option.category}>
                      {option.category}
                    </MenuItem>
                  ))}
                </TextField>
                <Stack spacing={2}>
                  <Button variant="contained" fullWidth onClick={() => { updateCard(card.id) }}>Lagre kort</Button>
                  <Button variant="outlined" color="error" fullWidth onClick={() => { deleteCard(card.id) }}>Slett kort</Button>
                </Stack>
              </CardContent>
            </Card>
          ))}

          <Paper elevation={0} sx={{ width: 400, height: 500, display: "flex", justifyContent: "center", alignItems: "center" }}>

            <div className='add-button-style' onClick={() => { addNewCard() }}>

              +

            </div>


          </Paper>




        </div>

      </div>


    </div>
  )
}

export default TipStreamAdmin