import React, { useState, useEffect } from 'react'
import { Paper, Typography, TextField, Button, Divider, Box, Stack, useMediaQuery, Snackbar, } from '@mui/material'
import RemoveIcon from '@mui/icons-material/Remove';
import MuiAlert from '@mui/material/Alert';
import { onSnapshot, collection, doc, deleteDoc, addDoc } from 'firebase/firestore';
import { db } from '../../firebase';
const TipsSettings = () => {
    const isPhone = useMediaQuery('(max-width:600px)');
    const [category, setCategory] = useState([])
    const [error, setError] = useState('')
    const [openMessage, setOpenMessage] = useState(false)
    const [openError, setOpenError] = useState(false)
    const [goodMessage, setGoodMessage] = useState("")
    const [newCategory, setNewCategory] = useState("")


    useEffect(() => {
        const catRef = collection(db, "tipscategory");
        const unsubscribe = onSnapshot(catRef, (snapshot) => {
            const data = snapshot.docs.map((doc) => {
                return { id: doc.id, ...doc.data() };
            });
            setCategory(data);
        });
        return () => {
            unsubscribe();
        };

    }, []);

    const addCategory = async () => {
        const catRef = collection(db, "tipscategory");
        try {
            const itemData = { category: newCategory };
            await addDoc(catRef, itemData);
            setNewCategory("")
        } catch (error) {
            setError('Error adding item:', error)
            setOpenError(true)
        }
    };

    const removeCategory = async (id) => {
        const catDocRef = doc(db, "tipscategory", id);
        try {
            await deleteDoc(catDocRef);
        } catch (error) {
            setError('Error removing item:', error)
            setOpenError(true)
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenError(false);
        setOpenMessage(false);
    };

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    return (
        <div className='tipssettings-content'>
            <Stack spacing={2} direction={isPhone ? "column" : "row"}>
                <Paper elevation={3}>
                    <Box sx={{ padding: 2 }}>
                        <Stack spacing={1}>
                            <Typography variant="h6">Kategori:</Typography>
                            <Divider />
                            {category.map((row, index) => (
                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <Typography key={index} variant="body1" fullWidth>{row.category}</Typography>
                                    <Button variant='outlined' sx={{ maxWidth: "min-content" }} onClick={() => { removeCategory(row.id) }}>
                                        <RemoveIcon />
                                    </Button>
                                </Box>
                            ))}

                            <TextField
                                id=""
                                label="Legg til kategori"
                                value={newCategory}
                                size='small'
                                onChange={(e) => { setNewCategory(e.target.value) }}
                            />
                            <Button onClick={addCategory}>legg til kategori</Button>
                        </Stack>
                    </Box>
                </Paper>
            </Stack>


            <Snackbar open={openMessage} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    {goodMessage}
                </Alert>
            </Snackbar>

            <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {error}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default TipsSettings


