import React, { useEffect, useState } from 'react'
import { collection, onSnapshot, updateDoc, doc, getDocs, query, orderBy, where } from 'firebase/firestore';
import { db } from '../../firebase';
import { UserAuth } from '../../context/AuthContext';
//Mui
import { DataGrid } from '@mui/x-data-grid';
import { Card, CardContent, Typography, Chip, Switch, Autocomplete, TextField, Button } from '@mui/material';
import { useNavigate } from 'react-router';
import moment from 'moment';
import "../../App.css"


const columns = [
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'date', headerName: 'Date', width: 150 },
    {
        field: 'usedInCase',
        headerName: 'Brukt i sak',
        width: 150,
        renderCell: RenderSwitchCell,
    },
    {
        field: 'tipsHandler',
        headerName: 'Tips behandler',
        width: 230,
        renderCell: RenderCell,
    },
    {
        field: 'Button',
        headerName: 'Button',
        width: 100,
        renderCell: RenderButtonCell,
    },
];

async function fetchHandlerOptions() {
    const optionsRef = collection(db, 'users');
    const snapshot = await getDocs(optionsRef);
    const options = snapshot.docs.map((doc) => doc.data().fullname);
    return options;
}
function RenderSwitchCell(params) {
    const { id, value } = params;
    const handleToggle = async (event) => {
        const newValue = event.target.checked;
        console.log(`Switch toggled for row with ID ${id}. New value: ${newValue}`);
        try {
            const docRef = doc(db, 'tips', id);
            await updateDoc(docRef, { usedInCase: newValue });
            console.log('Document updated successfully.');
        } catch (error) {
            console.error('Error updating document:', error);
        }
    };

    return (
        <Switch
            checked={value}
            onChange={handleToggle}
            color="primary"
            inputProps={{ 'aria-label': 'Switch' }}
        />
    );
}
function RenderCell(params) {
    const { id, value } = params;
    const [editing, setEditing] = useState(false);
    const [selectedHandler, setSelectedHandler] = useState(value);
    const [handlerOptions, setHandlerOptions] = useState([]);
    useEffect(() => {
        async function fetchOptions() {
            const options = await fetchHandlerOptions();
            setHandlerOptions(options);
        }
        fetchOptions();
    }, []);
    const handleDoubleClick = () => {
        setEditing(true);
    };
    const handleAutocompleteChange = (event, newValue) => {
        setSelectedHandler(newValue);
    };
    const handleAutocompleteBlur = async () => {
        setEditing(false);
        try {
            const docRef = doc(db, 'tips', id); // Replace 'tips' with the actual collection name
            await updateDoc(docRef, { tipsHandler: selectedHandler });
            console.log('Document updated successfully.');
        } catch (error) {
            console.error('Error updating document:', error);
        }
    };

    if (editing) {
        return (
            <Autocomplete
                value={selectedHandler}
                onChange={handleAutocompleteChange}
                onBlur={handleAutocompleteBlur}
                options={handlerOptions}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        autoFocus
                        variant="outlined"
                        size="small"
                        fullWidth
                    />
                )}
            />
        );
    }
    return (
        <Chip
            label={value}
            onDoubleClick={handleDoubleClick}
            clickable
            color="primary"
        />
    );
}
function RenderButtonCell(params) {
    const navigate = useNavigate()
    const { id, value } = params;
    const handleRedirect = () => {
        navigate(`../tip/${id}`)
        console.log(`tip/${id}`)
    };
    return (
        <Button onClick={handleRedirect} color="primary">Til sak</Button>
    );
}

const MyTipsTabel = () => {
    const [data, setData] = useState([]);
    const { user } = UserAuth()
    useEffect(() => {
            console.log(user.displayName)
            const collectionRef = collection(db, 'tips');
            const q1 = query(collectionRef,  where("tipsHandler", "==", "Mathias"), orderBy("date", "desc"))
           // const q2 = query(collectionRef,  )
            const unsubscribe = onSnapshot(q1, (snapshot) => {
                const data = snapshot.docs.map((doc) => {
                    return { id: doc.id, ...doc.data(), date: moment(doc.data().date.toDate()).format('DD/MM/YYYY') };
                });
                setData(data);
            });
            return () => {
                unsubscribe();
            };
    }, []);


    console.log(data)



    return (
        <Card sx={{ height: "max-content", }} className="all-tips-card">
            <CardContent>
                <Typography variant="h5" component="div" sx={{ marginBottom: 2 }}>
                    Mine tips
                </Typography>
                <DataGrid rows={data} columns={columns} />
            </CardContent>
        </Card>
    )
}

export default MyTipsTabel