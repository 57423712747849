import React, { useState } from 'react'
import Sidebar from './Sidebar';
import NewTips from '../components/datagrids/NewTips';
import TipsSendt from './Cards/TipsSendt';
import TipsToday from './Cards/TipsToday';
import TipsWeek from './Cards/TipsWeek';
import TipsUsedInCase from './Cards/TipsUsedInCase';
import MobileSidebar from './MobileSidebar';
import TipsSent from '../components/Charts/TipsSent';
//MUI
import { useMediaQuery, Card, CardContent, Typography, CardActions, Button, Fab } from '@mui/material';

const Dashboard = () => {
  const isPhone = useMediaQuery('(max-width:990px)');
  const link = process.env.REACT_APP_SENDURL;
  console.log("link", link);


  //console.log(isPhone)

  const copyCodeToClipboard = () => {
    const codeToCopy = `
<iframe
  src="https://${link}/v2"
  title="Tips"
  width="100%"
  height="440px"
  frameBorder="0"
></iframe>
    `;

    navigator.clipboard.writeText(codeToCopy)
      .then(() => {
        alert('Code copied to clipboard!');
      })
      .catch((error) => {
        console.error('Error copying code to clipboard:', error);
      });
  };




  return (
    <div className='dashboard-main'>
      {isPhone ? (
        <MobileSidebar />
      ) : (
        <Sidebar className="sidebar-menu" />
      )}
      <div className='dashboard-main-content'>
        <div className='dashboard-card-stack'>
          <TipsSendt />
          <TipsToday />
          <TipsWeek />
          <TipsUsedInCase />
        </div>
        <div className='dashboard-card-stack'>
          <NewTips />
          <div className='dashboard-stack'>
            <TipsSent />
            <Card sx={{ marginTop: 2 }}>
              <CardContent sx={{ maxWidth: 400 }}>
                <Typography variant='h6'>
                  Legg til insending av tips i en sak.
                </Typography>

                <Typography variant='body1'>
                  Trykk på knappen å koden som trengs vil bli kopiert til utklippstavlen, og du kan lime det inn i saken du ønsker.
                </Typography>
                <CardActions>
                  <Button onClick={copyCodeToClipboard}>Hent koden</Button>
                </CardActions>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard


