import React from 'react'
import Logo from "../img/logo.svg"

//mui
import { Stack, Button, Box, ListItemButton } from '@mui/material'
import { ThemeProvider, createTheme } from '@mui/material/styles';
//icons
import SettingsIcon from '@mui/icons-material/Settings';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import DescriptionIcon from '@mui/icons-material/Description';
import StreamIcon from '@mui/icons-material/Stream';
import AssistantIcon from '@mui/icons-material/Assistant';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import FolderIcon from '@mui/icons-material/Folder';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import QuizIcon from '@mui/icons-material/Quiz';
import CelebrationIcon from '@mui/icons-material/Celebration';
//kanskje
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import RedeemIcon from '@mui/icons-material/Redeem';

import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#474aee',
    },
    secondary: {
      main: '#f50057',
    },
  },
  typography: {
    fontFamily: 'Inter Medium, sans-serif', // Replace with your desired font family
  },
});

const Sidebar = () => {
  const navigate = useNavigate()
  const toDashboard = () => navigate("../dashboard")
  const toTips = () => navigate("../tips")
  const toMyTips = () => navigate("../mytips")
  const toCloudShift = () => navigate("../cloudshift")
  const toTipStream = () => navigate("../admin-tipstream")
  const toSettings = () => navigate("../settings")
  const toQuiz = () => navigate("../quiz")
  const toCrossword = () => navigate("../crosswords")
  const toComp = () => navigate("../konkuranse")



  return (
    <ThemeProvider theme={theme}>
      <Box className='sidebar-main'>
        <Box className="sidebar-content">
          <img src={Logo} alt="logo" className='sidebar-logo' onClick={toDashboard} />
          <Stack spacing={2}>
            <Button size="large" sx={{ minWidth: 200 }} startIcon={<AnnouncementIcon />} onClick={toTips}>Tips</Button>
            <Button size="large" sx={{ minWidth: 200 }} startIcon={<AssistantIcon />} onClick={toMyTips}>Mine tips</Button>
            <Button size="large" sx={{ minWidth: 200 }} startIcon={<UploadFileRoundedIcon />} onClick={toCloudShift}>CloudShift</Button>
            {/* 
            <Button size="large" sx={{ minWidth: 200 }} startIcon={<AccessTimeIcon />} onClick={toCloudShift} variant='disabled'>Coming soon!</Button>
            <Button size="large" sx={{ minWidth: 200 }} startIcon={<StreamIcon />} onClick={toTipStream} >Tipstream</Button>
            */}
            {
              process.env.REACT_APP_QUIZ === "true"
                ? <Button size="large" sx={{ minWidth: 200 }} startIcon={<QuizIcon />} onClick={toQuiz}>Konkuranser</Button>
                : null
            }
            {
              process.env.REACT_APP_CROSSWORD === "true"
                ? <Button size="large" sx={{ minWidth: 200 }} startIcon={<AutoStoriesIcon />} onClick={toCrossword}>Kryssord</Button>
                : null
            }

            {
              process.env.REACT_APP_COMP === "true"
                ? <Button size="large" sx={{ minWidth: 200 }} startIcon={<RedeemIcon />} onClick={toComp}>Konkuranse</Button>
                : null
            }

            <Button size="large" sx={{ minWidth: 200 }} startIcon={<CelebrationIcon />} > <a href="https://tipsight.no/oppdatering-knytt-til-bilde-og-video/" target="_blank" rel="noreferrer" style={{ "textDecoration": "none", "all": "unset" }}>Nyheter</a></Button>

          </Stack>
          <Button size="large" sx={{ minWidth: 200, position: 'absolute', bottom: 20 }} startIcon={<ManageAccountsOutlinedIcon />} onClick={toSettings}>Settings</Button>
        </Box>

      </Box>
    </ThemeProvider>
  )
}

export default Sidebar