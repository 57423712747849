import React, { useState, useEffect } from 'react'
import Sidebar from '../../Dashboard/Sidebar'
import { getStorage, ref, deleteObject } from "firebase/storage";
import Typography from '@mui/material/Typography'
import { Stack, Divider, Box, Skeleton, useMediaQuery, Button } from '@mui/material'
import { db } from '../../firebase'
import { doc, onSnapshot, deleteDoc } from 'firebase/firestore'
import { useParams, useNavigate } from 'react-router'
import MobileSidebar from '../../Dashboard/MobileSidebar'
import { UserAuth } from '../../context/AuthContext';


const OneTips = () => {
    const Navigate = useNavigate()
    const isPhone = useMediaQuery('(max-width:600px)');
    const { id } = useParams()
    const [data, setData] = useState({})

    useEffect(() => {
        const documentRef = doc(db, 'crosswords', id);
        const unsubscribe = onSnapshot(documentRef, (documentSnapshot) => {
            if (documentSnapshot.exists()) {
                setData(documentSnapshot.data());
            } else {
                Navigate("/dashboard")
            }
        });

        return () => {
            unsubscribe();
        };
    }, [id]);


    const handleDeleteTips = async () => {
        try {
            await deleteDoc(doc(db, "crosswords", id));
            Navigate("/crosswords")
        } catch (error) {
            console.log("Det var en feil ved sletting av dokument ", error, " Prøv igjen eller kontakt Systemansvarlig")
        }

    }


    return (
        <div className='onetips-main'>
            {isPhone ? (
                <MobileSidebar /> // Show this component on phones
            ) : (
                <Sidebar className="sidebar-menu" />
            )}
            <div className='onetips-main-container'>
                {data && Object.keys(data).length > 0 ? (
                    <div className='onetips-main-content'>
                        <Typography variant="h4" color="primary">Svar</Typography>
                        <br />
                        <div className='onetips-stack-row'>
                            <div>
                                <div className='onetips-stack'>
                                    <Box className="onetips-info-container">
                                        <Typography variant="h6" color="primary">Svar</Typography>
                                        <Typography color="initial">Svar : {data.answer}</Typography>

                                    </Box>
                                    <Divider />
                                    <Box className="onetips-info-container ekstra-space">
                                        <Typography variant="h6" color="primary">Kontakt informasjon</Typography>
                                        <Typography color="initial">Navn : {data.name}</Typography>
                                        <Typography color="initial">Telefon : {data.phone}</Typography>

                                    </Box>
                                    <Box className="onetips-info-container ekstra-space">
                                        <Typography variant="h6" color="primary">Adresse</Typography>
                                        <Typography color="initial">Addresse : {data.adress}</Typography>
                                        <Typography color="initial">Post kode : {data.postalcode}</Typography>
                                    </Box>

                                    <Button onClick={handleDeleteTips} variant='outlined' color="error" autoFocus>
                                        Slett kryssord
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div>
                            <Stack spacing={1}>
                                <Skeleton variant="text" sx={{ fontSize: '4rem' }} width={210} />
                                <Skeleton variant="rectangular" width={350} height={350} />
                                <Skeleton variant="rounded" width={300} height={60} />
                                <Skeleton variant="rounded" width={300} height={100} />
                            </Stack>
                        </div>
                    </div>
                )}
            </div>



            {/** 
      <Dialog
        open={deletePopup}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Slett tips"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Du er i ferd med å slette ett tips, er du sikker? Det vil ikkje være mulig å gjennopprette.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant='outlined'>Avbryt</Button>
          <Button onClick={handleDeleteTips} variant='contained' autoFocus>
            Slett tips
          </Button>
        </DialogActions>
      </Dialog>

      */}
        </div>
    );

}

export default OneTips