import React, { useState, useEffect } from 'react';
import { UserAuth } from '../../context/AuthContext';
import { Paper, Typography, Button, Divider, Box, Stack, useMediaQuery, Snackbar, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { onSnapshot, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import Switch from '@mui/material/Switch';
import MuiAlert from '@mui/material/Alert';
import SetDisplayName from '../../components/settings/SetDisplayName';


const MyUser = () => {
    const isPhone = useMediaQuery('(max-width:600px)');
    const { user } = UserAuth()
    const { resetPassword } = UserAuth()

    const [userSettings, setUserSettings] = useState({})
    const [email, setEmail] = useState("");
    const [displayNameDialog, setDisplayNameDialog] = useState(false)
    const [error, setError] = useState('')
    const [openMessage, setOpenMessage] = useState(false)
    const [openError, setOpenError] = useState(false)
    const [goodMessage, setGoodMessage] = useState("")
    const [showPassword, setShowPassword] = useState(false)
    const documentRef = doc(db, 'users', user.uid);

    useEffect(() => {
        const unsubscribe = onSnapshot(documentRef, (documentSnapshot) => {
            if (documentSnapshot.exists()) {
                setUserSettings(documentSnapshot.data());
            } else {
                console.log('Document does not exist');
            }
        });
        return () => unsubscribe();
    }, []);

    console.log(user.uid)


    const handleReciveNotification = async (e) => {
        console.log(e.target.checked)
        await updateDoc(documentRef, {
            wantNotifications: e.target.checked
        });
    }

    const handleCloseDisplayNameEdit = () => {
        setDisplayNameDialog(false)
    }

    const handleClosePasswordDialog = () => {
        setShowPassword(false)
    }


    const handleResetPassword = async () => {
        try {
            await resetPassword(email);
            setError(null);
        } catch (error) {
            setError(error.message);
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenError(false);
        setOpenMessage(false);
    };

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });




    return (
        <div className='users-content'>
            <Stack spacing={2} direction={isPhone ? "column" : "row"}>
                <Paper elevation={3}>
                    <Box sx={{ padding: 4 }}>
                        <Typography variant="h6">Mine instillinger</Typography>
                        <Divider />
                        <Stack spacing={2} sx={{ marginTop: 2, }}>
                            <Stack direction="row" spacing={2}>
                                <Typography variant="body1">Navn :</Typography>
                                <Typography variant="body1">{user.displayName}</Typography>
                                <Button onClick={() => { setDisplayNameDialog(true) }}>Rediger</Button>
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <Typography variant="body1">Epost :</Typography>
                                <Typography variant="body1">{user.email}</Typography>
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <Typography variant="body1">Motta varsel :</Typography>
                                <Switch checked={userSettings.wantNotifications} onChange={handleReciveNotification} inputProps={{ role: 'switch' }} />
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <Typography variant="body1">tilbakestill passord</Typography>
                                <Button color="error" variant="outlined" onClick={() => { setShowPassword(true) }}>Tilbakestill</Button>
                            </Stack>

                        </Stack>
                    </Box>
                </Paper>
            </Stack>
            <Snackbar open={openMessage} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    {goodMessage}
                </Alert>
            </Snackbar>

            <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {error}
                </Alert>
            </Snackbar>

            <Dialog open={displayNameDialog} onClose={handleCloseDisplayNameEdit}>
                <SetDisplayName onClose={handleCloseDisplayNameEdit} />
            </Dialog>

            <Dialog
                open={showPassword}
                onClose={handleClosePasswordDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Tilbakestill passord?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Ønsker du å tilbakestille eller endre passordet ditt?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClosePasswordDialog}>Nei</Button>
                    <Button onClick={handleResetPassword} autoFocus variant='outlined'>
                        Tilbakestill
                    </Button>
                </DialogActions>
            </Dialog>






        </div>
    )
}

export default MyUser

