import React from 'react'
import Logo from "../img/logo-icon.svg"

//mui
import { Stack, Button, Box, ListItemButton } from '@mui/material'
import { ThemeProvider, createTheme } from '@mui/material/styles';
//icons
import SettingsIcon from '@mui/icons-material/Settings';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import DescriptionIcon from '@mui/icons-material/Description';
import StreamIcon from '@mui/icons-material/Stream';
import AssistantIcon from '@mui/icons-material/Assistant';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import FolderIcon from '@mui/icons-material/Folder';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useNavigate } from 'react-router';

const theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#474aee',
        },
        secondary: {
            main: '#f50057',
        },
    },
    typography: {
        fontFamily: 'Inter Medium, sans-serif', // Replace with your desired font family
    },
});

const MobileSidebar = () => {
    const Navigate = useNavigate()
    const toDashboard = () => Navigate("../dashboard")
    const toTips = () => Navigate("../tips")
    const toMyTips = () => Navigate("../mytips")
    const toCloudShift = () => Navigate("../cloudshift")
    const toTipStream = () => Navigate("../admin-tipstream")


    return (
        <ThemeProvider theme={theme}>
            <Box className='mobile-sidebar-main'>
                <Box className="mobile-sidebar-content">
                    <img src={Logo} alt="logo" className='mobile-sidebar-logo' onClick={toDashboard} />
                    <Stack spacing={2}>
                        <Button size="large" onClick={toTips}><AnnouncementIcon /></Button>
                        <Button size="large" onClick={toMyTips}><AssistantIcon /></Button>
                        <Button size="large" onClick={toCloudShift}><UploadFileRoundedIcon /></Button>
                        <Button size="large" onClick={toCloudShift} variant='disabled'><AccessTimeIcon /></Button>
                        <Button size="large" onClick={toTipStream}><StreamIcon /></Button>
                    </Stack>
                </Box>
            </Box>
        </ThemeProvider>
    )
}

export default MobileSidebar